<template>
  <div class="customerlist">
    <div class="top">
      <div class="querybox">
        <div>
          <p>手机号/登陆账号:</p>
          <el-input
            v-model="queryParams.phone"
            placeholder="请输入手机号/登陆账号"
            clearable
          ></el-input>
        </div>
        <div>
          <p>昵称:</p>
          <el-input
            v-model="queryParams.nickname"
            placeholder="请输入昵称"
            clearable
          ></el-input>
        </div>
        <div>
          <p>时间筛选</p>
          <el-date-picker
            v-model="value"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="timeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="handlebox">
        <div class="btnbox">
          <el-button
            type="primary"
            icon="el-icon-search"
            class="blue-btn"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            class="gray-btn"
            style="color: #1d2129"
            @click="resetFn"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        show-overflow-tooltip
      >
        <el-table-column prop="headImage" label="头像">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.headImage" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号/登陆账号"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="updatedTime" label="注销时间" min-width="120">
        </el-table-column>
        <el-table-column prop="logoutReason" label="注销原因" min-width="120">
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
  </div>
</template>

<script>
import { getLogoutList } from "@/api/login";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: "",
        nickname: "",
        startTime: "",
        endTime: "",
      },
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      total: 0, //总数
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取用户列表
    async init() {
      const res = await getLogoutList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    timeChange(event) {
      // console.log(event);
      if (event) {
        this.queryParams.startTime = this.$moment(event[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.queryParams.endTime = this.$moment(event[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      // console.log(this.queryParams);
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.phone = "";
      this.queryParams.nickname = "";
      this.queryParams.pageNum = 1;
      this.queryParams.startTime = "";
      this.queryParams.endTime = "";
      this.value = "";
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.customerlist {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    padding-bottom: 10px;
    ::v-deep .querybox {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        margin-right: 22px;
        p {
          color: #4e5969;
          line-height: 22px;
          margin-right: 16px;
          white-space: nowrap;
        }
        .el-input,
        .el-range-editor {
          width: 256px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          color: #86909c;
        }
      }
      .btnbox {
        display: flex;
        .el-button {
          padding: 10px 0;
          width: 84px;
          box-sizing: border-box;
          // margin-left: 22px;
        }
      }
    }
    .handlebox {
      display: flex;
      align-items: center;
      .el-button {
        padding: 10px 0;
        width: 105px;
        box-sizing: border-box;
        // margin-left: 22px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 38px;
        height: 38px;
        border-radius: 100%;
      }
    }
    .el-button {
      padding: 5px 0;
      width: 80px;
      box-sizing: border-box;
    }
  }
  .textred-btn,
  .textblue-btn {
    padding: 0 5px;
  }
}
</style>
